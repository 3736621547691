import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

type AppProviderProps = {
  children: React.ReactNode;
};

const queryClient = new QueryClient();

// Suspense for lazy loading
export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <Suspense fallback={
      <div className="flex items-center justify-center w-screen h-screen">
        Loading...
      </div>
    }>
      <QueryClientProvider client={queryClient}>
        <Router>
          {children}
        </Router>
      </QueryClientProvider>
    </Suspense>
  );
};
