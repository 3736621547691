import React from 'react';
import { useRoutes, Outlet } from 'react-router-dom';

import { MainLayout } from '@/components/Layout';

const Dashboard = React.lazy(() => import('@/features/dashboard').then(module => ({ default: module.Dashboard })));

const App = () => {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

export const AppRoutes = () => {
  const element = useRoutes([
    {
      path: '',
      element: <App />,
      children: [{
        path: '', element: <Dashboard />
      }]
    },
  ]);

  return <>{element}</>;
};
